.alert-box-wrapper{
    position: fixed;
    max-width: 470px;
    margin: 6vh auto 0;
    left: 0;
    right: 0;
    padding: 0 20px;
    opacity: 0;
    pointer-events: none;
    transform:translateY(-20px);
    transition: transform 0.5s;
    z-index: 101;
    
    .close-box{
        background: #000;
        text-align: center;
        width:25px;
        height:25px;
        color: #fff;
        font-weight: bold;
        line-height: 23px;
        font-size: 16px;
        border-radius: 100px;
        cursor: pointer;
        position: absolute;
        right: 30px;
        top: 10px;
    }

    &.active{ 
        color: #000;
    }

    .alert-box{
        background: #fff;
        text-align: center;
        border-radius: 4px;
        padding: 20px;
        font-size: 15px;
        box-shadow: 0px 0px 4px #004580;
        &.pt{
            padding-top: 40px;
        }
    }
 
    &.show{
        opacity: 1;
        pointer-events: inherit; 
        transform:translateY(0); 
    }

    .text-left{
        text-align: left;
        padding-left: 20px;
    }
}

.MuiAlert-icon{
    align-items: center;
}


.returnVehicle-pop-up{
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    left:0;
    right: 0;
    top:0;
    bottom:0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    z-index: -9;
    transition: 0.5s;

    .inner{
        background: #fff;
        padding:50px 20px 20px;
        position:relative;
        transform:translateY(-20px);
        transition: 0.5s;
   
    }

    &.show{
        opacity: 1;
        pointer-events: initial;
        z-index: 999;

        .inner{ 
            transform:translateY(0);
        }
    }

    .btn-wrapper{
        display: flex;
        justify-content: center;
    }

    .btn{
        margin: 20px 10px;
    }

    h3{
        font-weight: normal;
        text-align: center;
        color: #000;
    }

    .close{
        position: absolute;
        right:20px;
        top:10px;
        cursor: pointer;
        font-size: 20px;
    }
} 