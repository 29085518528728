.step-wrapper{
    display: flex;

   
    .step{
        width: 70px;
        height: 70px;
        border-radius: 50%;
        text-align: center;
        border:2px solid #787878;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-bottom: 10px;
    }

    .step-title{
        text-align: center;
   
    }

    i{
        color: #787878;
        font-size: 30px;
    }

    .separator{
        margin-top: 35px;
        height: 1px;
        background: #787878;
        width: 100%;
    }

    .single-step{
        text-align: center;
        width: 134px;
        flex-shrink: 0;
        
        
        &.active{

            .step{
                background: #5d78ff;
                border:2px solid #5d78ff;
                

                i{
                    color: #fff;
                }
            }
          
        }
        &.error{

            .step{
                background: red;
                border:2px solid red;
                

                i{
                    color: #fff;
                }
            }
          
        }
        &.locked *{
            cursor:not-allowed;
        }
    }

}

@media only screen and (max-width: 767px) {
    .step-wrapper{
        i {
            color: #787878;
            font-size: 16px; 
        }
        
        .step{
            width: 35px;
            height: 35px;
        }
        
        .step-title{
            display: none;
        }
        
        .single-step{
            width: auto
        
        }
        .separator{
            margin-top: 18px;
        }
    }  

}