.MuiPaper-root{
    -webkit-box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05)!important;
    box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05)!important;
}
.dataTable-title{
    margin-top: 20px;
    font-size: 1.25rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    color: rgba(0, 0, 0, 0.87); 
}
.MuiSelect-select{
    padding: 19px 14px!important;
}
.MuiFormLabel-root.Mui-disabled {
    color:#9a9a9a!important;
}
.MuiFormLabel-root {
    text-transform: capitalize;
}
.select-styles{
    .MuiInputLabel-outlined {
        z-index: 1;
        transform: translate(16px, 19px) scale(1);
        pointer-events: none;
    }
}

.MuiMenu-paper { 
    border: 1px solid #c4c4c4;
}
.MuiTableSortLabel-root { 
    align-items: flex-start!important;
}
.MuiFormLabel-root {
    color: rgba(0, 0, 0, 0.54);  
    line-height: 1.2!important; 
}
.MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.1);
}
.MuiInput-underline:hover:not(.Mui-disabled):before{
    border-bottom: 2px solid rgba(160, 160, 160, 0.8)!important;
}
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(160, 160, 160, 0.8)!important;
} 
.MuiTablePagination-selectIcon {
    top: 15px!important;
    right: -5px!important;
}
.MuiOutlinedInput-notchedOutline{
    border-color: #e2e5ec;
}

.MuiInputBase-input { 
    height: 17px;
}


.MuiFormControl-root{
    i{
        color: #646c9a;
    }
    .Mui-error{
        i{
            color: #f44336;
        }
    }
   
    // legend{
    //     margin-left: 30px;
    // }
}
.MuiOutlinedInput-adornedStart {
    padding-left: 10px;
}
.cars-form{
    width: 100%;
    .MuiInputLabel-outlined.MuiInputLabel-shrink{
        font-size: 11px!important;
        transform: translate(14px, -6px) scale(1);
    }
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    color: #646c9a;
    font-size: 16px!important;
    // transform: translate(43px, -6px) scale(0.75)!important;
}

.MuiPopover-paper{

    li{
        font-size: 13px!important;
    }
}
.MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: -35px;
    margin: 0;
    min-height: 30px;
}

.col-width{
    table{
        table-layout: fixed;
    }
    th:nth-child(1){ 
        width: 50px;
    }
    th:nth-child(n+2){ 
        width: 160px;
    }
}

@media only screen and (max-width: 1366px) { 
    .MuiOutlinedInput-input {
        padding: 14px!important;
    } 
    .MuiTypography-h6{
        font-size: 1rem!important;
    }
    .MuiTableCell-root{
        font-size: 0.8rem!important;
    }
    .MuiTableCell-head { 
        line-height: 1rem!important; 
    }
    .MuiSelect-select{
        padding: 14px!important;
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        padding: 0px!important;
    }

    .select-styles .MuiInputLabel-outlined {
        z-index: 1;
        transform: translate(16px, 12px) scale(1);
        pointer-events: none;
    }  
    .MuiTypography-body1 {
        font-size: 14px;
    } 
}
@media only screen and (max-width: 767px) { 
  
    .MuiTablePagination-selectIcon {
        top: 9px!important;
    }
    .MuiTablePagination-toolbar{
        p:first-of-type{
            display: none;
        }
    }
    .MuiPopover-paper{
        max-width: 100% !important;
        width: 100%;
        left: 0 !important;
        background-color: transparent!important;
        padding: 0 20px;
        border: none;
        
        .MuiMenu-list,
        .MuiFormControl-root{ 
            width: 100%;
            background-color: #fff;
            box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05) !important;
        }
    }

 
 
    .MuiFormHelperText-root.Mui-error {
        position: static; 
        min-height: 30px;
        display: flex;
        align-items: center;
    }

    .MuiTable-root{
        td{
            padding: 10px 20px;
            height: 38px;
            font-size: 12px;
            div{font-size: 12px;}
        }
    }
    
}