.TopBar{
    background: #fff; 
    // position: absolute;
    padding:19px 25px;
 

    .logout{
        cursor: pointer;
        transition: background 0.5s;
        span{
            margin-right: 10px;
        }

        &:hover{
            background: #2d7199;
            
        }
    }
}

@media only screen and (max-width: 767px) {
    .TopBar{
        padding:10px;
       .top-nav-wrapper{
            display:flex;
            justify-content: space-between;
            margin-bottom: 20px;

        }
        .btn-label-brand{
            display: none!important;
        }

        .top-button{
            font-size: 9px;
            padding: 5px 10px;
          
            i{
                margin-right: 2px;
            }
        }
    }
}