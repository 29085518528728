.btn-label-brand{
    cursor: pointer !important;
    height: 34px !important;
    background-color: rgba(93, 120, 255, 0.1);
    color: #5d78ff;
    font-weight: bold;
    vertical-align: middle;
    padding:  0 13px!important;
    text-align: center;
    border: 1px solid transparent;
    line-height: 34px!important;
    display: inline-block!important;

    i{
        margin-left: 10px;
    }
}

.fa-sync-alt.loading{
    animation: rotateAnimation 1.2s linear infinite;
}
@keyframes rotateAnimation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
:focus {
    outline: none;
}
.mr-0{
    margin-right: 0!important;
}
.uploadDocumentWrap{
    position:relative;
    margin-top: 10px;
    margin-bottom: 10px;
    input{
        opacity:0;
        margin:0;
        position:absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%
    }
}
.clearFilters-btn{
    color: rgba(0, 0, 0, 0.87);
    border: none;
    cursor: default;
    height: 32px;
    display: inline-flex;
    outline: 0;
    padding: 0;
    font-size: 0.8125rem;
    box-sizing: border-box;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    white-space: nowrap;
    border-radius: 16px;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: #e0e0e0;
    margin: 8px 8px 0px 0px;
    cursor: pointer;

    .text{
        overflow: hidden;
        white-space: nowrap;
        padding-left: 12px;
        padding-right: 12px;
        text-overflow: ellipsis;
        color: rgba(0, 0, 0, 0.87);
    }

    .fas{
        color: #a6a6a6;
        font-size: 22px;
        width: 22px;
        cursor: pointer;
        height: 22px;
        margin: 0 5px 0 -6px;
    }
}
.btn{ 
    text-align: center;
    display: inline-block;
    padding: 13px 16px;
    font-size: 0.875rem; 
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;  
    cursor: pointer; 
    outline: 0;   
    border:none;
    user-select: none; 
    -moz-appearance: none; 
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    border: 1px solid rgba(0,0,0,.23);
    // box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);


    i{
        margin-right: 10px;
    }

   &.select{ 
       &.active{
            background: green;
            color: #fff; 
       }
   }

   &.mr-10{
       margin-right: 10px;
   }

   &.small{
    padding: 5px 16px;
    white-space: nowrap;
   }

   
}
table .info-text{
    width: 178px;
    padding: 4px 0; 
    font-size: 10px; 
    text-align: center;
}

.offer-allow-btn{
    width: 178px;
   
    .btn.small {
        padding: 4px 10px;
        text-transform: capitalize;
        font-size: 10px;
    }
    .MuiOutlinedInput-inputMarginDense {
        padding-top: 6px;
        padding-bottom: 6px;
        height: 15px;
    } 
}
.small-text-area.MuiFormControl-marginDense {
    margin-top: 0px;
}
.small-text-area.MuiFormControl-marginDense .MuiOutlinedInput-multiline{
    padding-top: 8px;
    padding-bottom: 9px;
}

.btn-primary { 
    background-color: #1976d2; 
    color: #ffffff!important;
    

    i{
        margin-right: 10px;
    }
}
.btn-success {
    background-color: #1dc9b7;
    border-color: #1dc9b7;
    color: #fff;

    &:hover{
        color: #fff;
        background-color: #18a899;
        border-color: #179c8e; 
    }
}

.btn-primary:hover { 
    background-color: rgb(17, 82, 147);
}

.btn-primary { 
    background-color: #1976d2; 
    color: #ffffff!important;

    i{
        margin-right: 10px;
    }
}

.btn-primary:hover { 
    background-color: rgb(17, 82, 147);
}

.btn-info {
    color: #ffffff!important;
    background-color: #5867dd;  
}
.btn-secondary {
    border: 1px solid #e2e5ec;
    color: #595d6e;
    background: #fff;
    box-shadow:none;
    i{
        margin-right: 10px;
    }
}

.btn-info:hover {
    color: #ffffff!important;
    background-color: #384ad7;
}


form {
    .btn{
        margin-top: 16px;
    }
}

.btn-focus{
    background-color: #9816f4;
    border-color: #9816f4;
    color: #fff;
 
    i{
        margin-right: 10px;
    }
}

.btn-focus:hover {
    background-color: #840ad9;
    border-color: #7c0acd;
}

.btn-danger{
    background-color: #fd397a;
    border-color: #fd397a;
    color: #fff;
    

    i{
        margin-right: 10px;
    }
}

.btn-danger:hover {
    background-color: #fd1361;
    border-color: #fc0758;
}

.text-label{
    margin-top: 16px; 
    border-radius: 5px;
    border:2px solid red;
    color: red;
    text-align: center;
    font-weight: bold;
    padding: 14.5px 14px;

    &.free{
        border:2px solid green;
        color: green;
    } 
}

.btn-wrapper{
    li{
        width: 50%;
        display: inline-block;
        padding: 0 20px;
    }
}
.btn-warning{
    background-color: #ffa238;
    color: #fff;
    border-color: #ff9b00;
}

.top-button{
    border: 1px solid #365fbe;
    color: #ffffff;
    background: #5867dd;

    &.active{
        background-color: #444a7a;
        border-color: #152d2b;
        color: #fff;
    } 
}

@media only screen and (max-width: 1366px) {
    .btn {
        text-align: center;
        display: inline-block;
        padding: 8px 12px;
        font-size: 12px;
    }
}
@media only screen and (max-width: 767px) {
    .MuiIconButton-root{
        right:30px!important;
    }
    .btn { 
        padding: 10px 13px;  
        font-size: 12px;
    }   
    table{
        .btn.small {
            padding: 4px 16px;
            white-space: nowrap;
            margin-top: -20px;
            font-size: 10px;
        }
    }
}

