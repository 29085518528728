body{
    min-height:100vh;
    background-color: #f2f3f8;
    font-family: 'Roboto';
    font-size: 13px;
    font-weight: 300;
    line-height: 1.5;
    color: #646c9a;
    padding: 0!important;

}
html,body{
    width: 100%;
}
.App{
    overflow-x: hidden;
}
.MuiFormLabel-root,
.MuiInputBase-root{
    font-size: 13px!important;
}

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


ul{
    list-style-type: none; 
}

a{
    text-decoration: none;
}


.mb-10{
    margin-bottom: 10px!important;
}
.mb-20{
    margin-bottom: 30px!important;
}
.mt-20{
    margin-top: 30px!important;
}

.text-center{
    text-align: center;
    display: block;
}

.padding{
    padding: 20px;
}

.padding-submit{
    padding: 0 20px 20px;
}

tr{
    cursor: pointer;
}

.text-center{
    text-align: center;
}

.section-title-wrapper{
    border-bottom: 1px solid #ebedf2;
}

.section-title{
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
    font-weight: 500;
    color: #48465b;  

    i{
        margin-right: 10px;
    }
}

button[disabled]{
    cursor: no-drop
}
.full-width{
    width: 100%;
}

.show-on-mobile{
    display: none;
}
 
.checkbox-label{
    border:1px solid #ebedf2;
    
    .MuiCheckbox-root{
        padding: 5px;
        margin-left: 15px;
    }
}

.static{
    .MuiPaper-root{
        position: static!important;
    }
}
.scroll-x{
    overflow-x: scroll!important;
}


@media only screen and (max-width: 767px) {
    .hide-on-mobile{
        display: none!important;
    }
    .show-on-mobile{
        display: block;
    }

    .section-title{
        font-size: 1.1em; 
    }

    .padding{
        padding:20px 10px;
    }
    
    .btn-secondary{
        margin-top: 20px;
    }
    form{
        
        .mb-20 {
            margin-bottom: 5px !important;
        }
    } 
}


