tr[style="background: red;"]{
    td{
        color:#fff;     
    }
}

.custom-table{
    width: 100%;
    border-collapse: collapse;
    text-align: center;

    th{
        border-bottom:1px solid #ebedf2;
        padding: 10px;
    }

    td{
        text-align: center;
        padding: 10px;
        border-bottom:1px solid #ebedf2;
    }

    &.equipment{
        tr:nth-of-type(even){
            background-color: #ebedf2;
        }
    }

    &.documents{
        tr:nth-of-type(even){
            background-color: #ebedf2;
        }
        th,
        td{
            text-align: left;
        }
    }
}

.permitions-table{
    margin-bottom: 30px;
    width: 100%;
    min-width: 600px;
    border-collapse: collapse;
    text-align: center;
    border-bottom:1px solid #ebedf2;

    th{
        border-bottom:1px solid #ebedf2;
        padding: 10px 10px 20px;  
        font-weight: bold;
        cursor: auto;
    }
    td{
        cursor: auto;
        padding:0 10px;
        &:nth-of-type(1){
            text-align: left;
            text-transform: capitalize;
            font-weight: bold;
        }
    }

    tbody{
        tr:nth-of-type(even){
            background: #ebedf2;
        }
    }
}

.roles-table{
    th:last-of-type,
    td:last-of-type{
        text-align: right;
    }
}
@media only screen and (max-width: 767px) {
    .equipment-wrapper{
        overflow:scroll;
        .equipment{
            width: 600px;
        }
    }
    .permitions-table-wrapper{
        overflow:scroll;
    }
    .mb-20-m{
        margin-bottom: 20px!important;
    }
}