.LogIn{
    min-height:100vh;
    background-color: #242939;
    width: 100%;
    padding-top: 64px; 

    .form-wrapper{ 
        max-width: 440px;
        margin:0 auto;
        text-align: center;
        padding: 20px 30px;
        border: 1px solid #ebedf2;
        border-radius: 10px;
        background: #fff;
    }
    .MuiInputLabel-outlined {
        z-index: 1;
        transform: translate(14px, 17px) scale(1);
        pointer-events: none;
    }
    .separator{
        border-bottom: 1px solid #ebedf2;
        margin-left: -30px;
        margin-right: -30px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    form{
        width: 100%;
        margin-top: 8px;
    }

    .avatar{
        margin: 8px;
        background-color: #5867dd;
        display: inline-block;
        line-height: 48px;
    }
    .submit{
        margin: 24px 0px 16px; 
    }

    .MuiButton-containedPrimary{
        background-color: #367fa9;

        &:hover{
            background-color: #2d7199;
        }
    }
}