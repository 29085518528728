.custom-filters{
    top: 16px;
    right: 20px; 
    padding: 24px 24px 36px 24px;
    font-family: Roboto;
    background-color: #fafafa; 
    box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05) !important;
    transition: opacity 398ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 265ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    outline: 0;
    position: fixed;
    max-width: calc(100% - 32px);
    min-width: 16px;
    max-height: calc(100% - 32px);
    min-height: 16px;
    overflow-x: hidden;
    overflow-y: auto;
    // max-width: 50%;
    width: 100%;
    border-radius: 4px;
    z-index: -1; 
    opacity:0;
    transform: scale(0.8);

    &.open{
        opacity:1;
        z-index: 999;  
        transform: scale(1);
    }
    .btn-info{
        margin-top: 20px;
    }
    .close-btn{
        position: absolute;
        right: 4px;
        top: 18px;
        z-index: 1000;
    }

    .filter-title{
        display: inline-block;
        margin-left: 20px;
        margin-right: 30px;
    }
    
    .MuiInputLabel-formControl {
        top: 0;
        left: 0;
        position: absolute;
        transform: translate(13px, 38px) scale(1);
    }

    .MuiInputLabel-shrink {
        transform: translate(13px, 15px) scale(1);
        transform-origin: top left;
    }

    .MuiSelect-select:focus{
        background-color: transparent!important;
    }
}
.custom-search{ 
    input{
        padding:10px 14px !important; 
        border:none; 
        border-bottom: 1px solid #b6b6b6;
        flex-grow: 1;
    }

    .btn{
        margin-top:0;
        margin-left: 15px; 
        padding: 5px 25px !important;
        i{
            margin-right: 0;
        }
    }
}
@media only screen and (min-width: 768px) {
    .custom-search {
        max-width: 400px;
        margin: 20px auto 0;
        display: flex;
        align-items: center; 
        position: absolute;
        width: 100%;
        left:350px;
        z-index: 2;
        input{ 
            width: 250px; 
        }
        // .btn{
        //     color:rgba(0, 0, 0, 0.87)!important;
        //     border:none!important;
        //     background: transparent;
        //     i{
        //         margin-right: 0;
        //     }
        // }
    }
}
@media only screen and (max-width: 767px) {
    .custom-search{
        margin-top: 50px; 
        input{ 
            background: #fafafa; 
        }
    }
    .custom-filters{
        max-width: 100%;
        width: auto;
        left:20px;

        .filter-title{
            margin-left: 0;
            margin-right: 10px;
        }

        .MuiIconButton-root{
            right: 4px!important;
        }
    }
}