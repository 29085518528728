 
.App{
    display: flex;
}

.form{
    width: 100%;
    padding-bottom: 20px;
}
.static{
    .MuiPaper-root{
        position: static!important;
        box-shadow: none!important;
    }
}
.scroll-x{
    overflow-x: scroll!important;
}
 
.MuiOutlinedInput-input{
    color: initial!important;
}
.main-container{ 
    // padding-left: 260px;
    flex-grow: 1; 
    width: 500px; 
    .wrapper{
        padding:20px;
        min-height: 100vh;
    } 

}

.p-tb-0{
    padding-bottom: 4px!important;
}
.textarea{
    textarea{
        height: 114px!important;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.aditional-accessory{
    display: flex;
    align-items: center;

    .number{
        margin-left: 20px;
        width: 90px;
        flex-shrink: 0;
    }
    .MuiOutlinedInput-input {
        padding: 10px 14px;
    }
    
}
.btn-box{
    width: 70px;
    flex-shrink: 0;
    margin-top: 5px;
    display: flex;
}
.add,
.delete{ 
    flex-shrink: 0;
    border:none;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-left: 10px;
    font-size: 10px;
    cursor: pointer;
}

.delete{
    background: red;
}

.add{
    background: #384ad7;
    font-size: 20px;

    font-weight: bold;
}
.image-wrapper{
    position: relative;

    &.poiner{
        cursor: pointer;
    }

    &.one-third{
        width: 30%;
        margin-right: 3%; 
        flex-shrink: 0;
    }
}

.image-section{
    display: flex;
    flex-wrap: wrap;
}
.image-holder{
    height: 250px;
    border:3px dashed #bdbdbd;
    position: relative;
    color: #bdbdbd;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p{
        font-size: 25px;
    }


    input{
        position: absolute;
        top:0;
        right:0;
        left:0;
        bottom:0; 
        opacity: 0;
        cursor: pointer;
        width: 100%;
    }

    &.small{
        margin-top: 20px;
        height: 70px; 
        font-size: 14px;
    }
}

.MuiPaper-root{
    &.input-size{
        padding: 8px 16px; 
        .MuiAlert-message{ 
            display: block!important;
            text-align: center;
        }
    }

}

.image-wrapper{
    .delete{
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 10;
        background:#fff; 
        color: #484848;
        font-size: 14px;
        padding: 10px;
        border-radius: 50%;
        border: 2px solid red;
        cursor: pointer;
        font-weight: bold;
        transition:0.5s;
        display: flex;
        align-items: center;
        i{ 
            font-size: 10px;
        }

        &:hover{
            background:red;
            color: #fff;
        }
    }
    .picked{
        position: absolute;
        top: 0;
        right: 0;
        bottom:0;
        left:0;
        margin:auto;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10; 
        color: #fff; 
        cursor: pointer; 
        transition:0.5s; 
        flex-direction: column;
        i{ 
            font-size: 25px;
            color:green;
            background:#fff;
            border-radius: 100%;
            padding: 3px;
        }

        p{
            text-shadow: 0 0 2px #000;
            font-weight: bold;
            font-size: 14px; 
        } 
    }
}
.chips{
    .chip{
        background: #9816f4;
        color: #fff;
        margin: 2px 6px;
        font-size: 16px;
        height: 38px;
        padding: 0 20px;
        text-transform: capitalize;
    }
}
.padding-lr{
    padding: 0 20px;
}
.MuiAlert-root{
    min-height: 68px;
}


.model-chip{
    background: #1683f4;
    color: #fff;
    margin: 3px 6px;
    font-size: 14px;
    height: 30px;
    padding: 0 14px;
    border-radius: 15px;
    text-transform: capitalize;
    transition: 0.5s;
    display: inline-block;
    line-height: 31px;
}

.model-chip:hover{
    background: #9100ff;
}
.MuiInput-underline:before{
    border-bottom: 1px solid rgb(196, 196, 196);
}

.chip-options{
    &.MuiListItem-root{
        font-size: 16px !important;
        padding:15px 20px;
        text-transform: capitalize;
    }


    &.MuiListItem-root.Mui-selected:hover,
    &.MuiListItem-root.Mui-selected{
        background-color: #9816f4;
        color: #fff;
    }
}


.nav-wrapper{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap; 
    flex-direction: column;
    cursor: default; 
}

.regular-table{
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 50px;

    th{
        border:none;
        border-bottom: 1px solid #ebedf2; 

      
    }
    td,
    th{
        padding: 10px; 
        text-align: center; 
    }

    td{
        padding: 15px 10px;

       
    }

    tr{
        &:nth-child(even){
            background: #f2f2f2;
        }
    }
}


@media only screen and (max-width: 767px) {
    .header-section{
        .btn{
            font-size: 10px;
            width: 100%;
        }
    }
    .header-section-inner{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .btn{
            font-size: 10px; 
            width: 45%;
        }
    }
    .App{
        display: block;
    }
    .main-container{ 
        padding-left: 0; 
        width: 100%;
    } 
    .section-title-wrapper{
        span{
           display: none; 
       }
       .btn-secondary{
           margin-top: 0;
            i{ 
                margin-right: 0;
            }
       }
    }
}
@media only screen and (max-width: 767px) {
    .scroll-x{
        .MuiIconButton-root{
            right: 0!important;
        }
    }
    .dataTable-title{ 
        text-align: center;
    }
    .m-order-1{
        order: 1
    }
    .m-order-2{
        order: 2
    }
    .m-order-3{
        order: 3
    }
    .aditional-accessory{
        flex-direction: column;
        .number{
            width: 100%;
            margin-left: 0;
        }
    }
}