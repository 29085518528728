.SideBar{
    min-height: 100vh;
    // width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 260px;
    background-color: #242939;
    // max-width: 260px;
    // float: left; 


    .logo{ 
        color: #fff;
        padding: 10px 0 0 25px; 
        line-height: 75px;
        overflow: hidden;
        transition: width;
        float: left;
        width: 200px; 
        img{
            width: 175px;
        }
    }

    .mobile-menu{
        position: absolute;
        display: none;
        right:20px;
        top:22px; 
        border-radius: 100%; 
        text-align: center;
        i{
            font-size: 25px;
            color: #5867dd;
            line-height: 35px;
            font-weight: bold;
        } 
    }

    .close-nav{
        position: absolute;
        display: none;
        right:20px;
        top:26px;
        background: #c0c4d1;
        border-radius: 100%;
        width:20px;
        height:20px;
        text-align: center;
        i{
            font-size: 10px;
            color: #242939;
        } 
    }
    i,
    [class^="flaticon-"],
    [class^="flaticon2-"] {
        color: #434d6b;
        font-size: 17px; 
        transition: color 0.5s;
    }
    .title{ 
        font-weight: bold;
        color: #606a8c;
        // padding: 100px 5px 25px 15px; 
        padding: 30px 9px 0 30px; 
        line-height: 40px;
        font-size: 11px; 
        text-transform: uppercase;
        letter-spacing: 0.3px;

    }


    .main-nav{ 
        li{ 
            &.drodown-menu{
                cursor: pointer;
                >span{
                    display: inline-block;
                    padding-bottom: 10px;
                }
            }

           >ul{
               display: none;
               a{
                    padding-left: 60px;
               }
                &.active{
                    display: block;
                }
           }
            .element,
            a{ 
                color: #c0c4d1;
                display: block;
                padding: 9px 30px;
                font-weight: 400; 
                line-height: 26px;
                text-transform: initial;
                transition: color 0.5s;
                // i{
                //     color: #c0c4d1;
                // }

                &:hover{
                    background-color: #1f2433;
                    >i,
                    >[class^="flaticon-"],
                    [class^="flaticon2-"] {
                        color: #5d78ff;
                    }
                  
                }
                &.active{
                    background-color: #1f2433;
                    i,
                    [class^="flaticon-"],
                    [class^="flaticon2-"] {
                        color: #5d78ff;
                    }
                  
                }

                
                span{
                    margin-left:10px; 
                }
               
                svg{
                    width: 20px;
                }
            }
 
        }
    }

    .expand-collapse{
        float: right;
        padding: 20px;
        transform: rotate(-180deg);
        cursor: pointer;
    }

    .flaticon2-soft-icons{
        color: #fff!important;
    }
    &.collapse{
        flex-basis: 75px;

        ul ul a{
            padding: 9px 30px;
        }

        .logo{ 
            width: 0; 
            padding:0;  
         
        }
        .expand-collapse{
            // float: right;
            transform: rotate(0);
            float: none;
            text-align: center;

            i{
                color: #000;
            }
        }
        .title{
            display: none;
        }
        .main-nav li span{
            display: none;
        }
    }
}

@media only screen and (max-width: 767px) {
    .expand-collapse{
        display: none;
    }
    .SideBar{ 
        min-height: auto;
        width: 100%;
        background-color: #242939;
        max-width: 100%;
        float: none;  


        .title{
            padding-top: 50px;
        }

        .mobile-menu,
        .close-nav{  
            display: block; 
        }
   

        .main-nav-wrapper{
            transition: 0.5s ease-in-out;
            transform: translateX(100%);
            top:0;
            position: fixed;
            z-index: 2;
            background-color: #242939;
            width: 100%;
            padding-bottom: 20px;
            height: 100vh;
            overflow-x: hidden;

            &.active{
                transform: translateX(0);
            }
        } 
    }
  }